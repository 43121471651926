<template>
  <div class="template-preview-view" v-if="visible" :style="`right: ${right}px;`">
    <div class="template-preview-body">
      <TemplateView ref="RefTemplateView"></TemplateView>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue'
import { message } from 'ant-design-vue'
import TemplateView from './TemplateView'
import { nextTick } from 'vue'

const props = defineProps({
  // 右边间距
  right: {
    type: Number,
    default: 0
  }
})
// 预览视图
let RefTemplateView = ref(null)
// 是否展示弹窗
let visible = ref(false)

// 展示弹窗
function show (id) {
  // 模版ID必须有值
  if (!id) {
    message.error('模版ID不能为空')
    return
  }
  // 显示
  visible.value = true
  // 延迟执行
  nextTick(() => {
    // 渲染预览
    RefTemplateView.value.load(id)
  })
}

// 关闭弹窗
function hide () {
  visible.value = false
}

defineExpose({
  show,
  hide
})

</script>

<style lang="less" scoped>
.template-preview-view {
  position: fixed;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .template-preview-body {
    padding: 24px;
    border-radius: 2px;
    background-color: #fff;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
}
</style>